<template>
  <div class="w-5/12" />
  <div class="w-3/12 flex justify-center">
    <InlineSvg :src="IrisLogoStandart" width="100" height="40" />
  </div>
  <div class="w-4/12 flex justify-end">
    <BackAdvisor />
  </div>
</template>

<script>
import IrisLogoStandart from '@/assets/svg/iris-logo-standard.svg'
import BackAdvisor from './BackAdvisor.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'ClientPreviewHeader',
  components: {
    BackAdvisor
  },
  setup() {
    const route = useRoute()

    const isShowLogo = computed(() => {
      return route.name !== 'lead/dashboard'
    })

    return {
      isShowLogo,
      IrisLogoStandart,
    }
  },
}
</script>