<template>
  <div class="mr-2.5 flex items-center">
    <span class="text-xss text-main mr-1.5">Items per page</span>
    <div class="w-16">
      <SwdNativeSelect :options="actions" size="small" :init-value="initValue" @select="handleItemsPerPage" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'SwdItemsPerPage',
  props: {
    actionsItemsPerPage: {
      type: Array,
      required: true,
      default: () => [],
    },
    destination: {
      type: String,
      required: true,
      default: 'defaultItemsPerPage',
    },
  },
  emits: ['selectPage'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const initValue = ref(String(store.state.globalComponents.itemsPerPage.values[props.destination]))
    const actions = ref(store.state.globalComponents.itemsPerPage.options)

    watch(initValue, (newValue) => {
      if (newValue !== store.state.globalComponents.itemsPerPage.values[props.destination]) {
        store.commit('globalComponents/setDestinationItemsPerPage', {
          destination: props.destination,
          value: newValue,
        })
      }
    })

    const handleItemsPerPage = (command) => {
      if (command !== initValue.value) {
        const routeParams = { ...router.currentRoute.value.params, p: '' }
        router.push({ name: router.currentRoute.value.name, params: routeParams })
        initValue.value = String(command)
        emit('selectPage', 1)
      }
    }

    return {
      handleItemsPerPage,
      initValue,
      actions,
    }
  },
}
</script>
