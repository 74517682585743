import dayjs from 'dayjs'
class UserFullInfo {
  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.address = data.address
    this.city = data.city
    this.created_at = data.created_at
    this.total_net_worth = data.total_net_worth
    this.step = data.step
    this.avatar = data.avatar
    this.onboarding = data.onboarding
    this.email = data.email
    this.is_activated = data.is_activated
    this.can_delete = data.can_delete
    this.can_restore = data.can_restore
    this.can_convert = data?.can_convert
    this.owner_id = data.owner_id
    this.state = data.state
    this.client_id = data.client_id
    this.member_id = data.id
    this.user_id = data.user_id
    this.is_deleted = data.is_deleted
  }

  get createdAtFormatted() {
    return dayjs(this.created_at).format('MM/DD/YYYY')
  }
}

export { UserFullInfo }
