<template>
  <div class="content">
    <router-view v-if="!loading" />
    <SwdFullScreenLoading v-else />
    <AutoLogout />
    <ModalReloadPage />
    <SwdShareDialog />
    <SwdModalExportSucces />
    <ChooseAccount />
    <SwdModalTerms />
    <SwdPreviewModal />
    <SwdModalSummaryInfo />
    <VueQueryDevTools />
  </div>
</template>

<script>
import { onMounted, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { fetchTransport } from './api/api-client/FetchTransport'
import { useQueryProvider } from 'vue-query'
import { VueQueryDevTools } from 'vue-query/devtools'
import { tokenStorage } from './api/api-client/TokenStorage'
import { useSockets } from './sockets/use-sockets'
import ModalReloadPage from '@/components/ModalReloadPage/ModalRealoadPage.vue'
import SwdShareDialog from '@/components/Global/SwdShareDialog.vue'
import SwdModalTerms from '@/components/Global/SwdModalTerms.vue'
import SwdPreviewModal from '@/components/Global/SwdPreviewModal.vue'
import SwdModalExportSucces from '@/components/Documents/ClientReport/ModalExportSucces.vue'
import SwdModalSummaryInfo from '@/components/SummaryInfo/SwdModalSummaryInfo.vue'
import { useRouter, useRoute } from 'vue-router'
import AutoLogout from './views/AutoLogout.vue'
import ChooseAccount from '@/components/Global/ChooseAccount.vue'
import { removeFromStorage } from '@/utils/utilsSessionStorage'
import { StatusCodes } from 'http-status-codes'

export default {
  components: {
    VueQueryDevTools,
    ModalReloadPage,
    SwdShareDialog,
    SwdModalExportSucces,
    ChooseAccount,
    SwdModalTerms,
    SwdPreviewModal,
    SwdModalSummaryInfo,
    AutoLogout,
  },
  setup() {
    useQueryProvider({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const loading = computed(() => {
      return store.state.globalComponents.isLoadingApp
    })

    // Check authentication status based on Vuex store
    const isAuthenticated = computed(() => store.state.auth.isAuth)
    const userId = store.state.globalComponents.userId

    const isUserLoggedIn = computed(() => userId !== null)


    onBeforeMount(async () => {
      const access_token = tokenStorage.getByKey('access_token')
      if (JSON.parse(tokenStorage.getByKey('refresh_token_expired'))) {
        sessionStorage.clear()
      }
      await router.isReady()

      const excludedRoutesForAuth = [
        'magic-email',
        'magic-login',
        'passwordcreate',
        'passwordreset',
        '503',
        'forgotpassword',
      ]
      //force fully redirect to login if unauthorized
      if (access_token === null && !excludedRoutesForAuth.includes(route.name)) {
        removeFromStorage(sessionStorage, 'access_token')
        removeFromStorage(sessionStorage, 'otp-type')
        removeFromStorage(sessionStorage, 'otp_verified')
        removeFromStorage(sessionStorage, 'refresh_token_expired')
        await store.dispatch('globalComponents/resetState')
        store.commit('auth/setAuthUser', false)
        sessionStorage.clear()
        router.push({ path: '/login' })
      }
    })

    onMounted(async () => {
      const auth = JSON.parse(tokenStorage.getByKey('auth'))
      const otp_verified = JSON.parse(tokenStorage.getByKey('otp_verified'))
      const otpType = JSON.parse(tokenStorage.getByKey('otp-type'))
      const access_token = tokenStorage.getByKey('access_token')
      const otpEnabled = sessionStorage.getItem('otp-enabled')
      const excludedRoutesForAuth = ['magic-email', 'magic-login', 'passwordcreate', 'passwordreset', '503']
      await router.isReady()
      if (!excludedRoutesForAuth.includes(route.name)) {
        if (
          access_token !== null &&
          auth &&
          auth.isAuth !== null &&
          (otp_verified === true || otpEnabled === 'false')
        ) {
          const { multipleAccount, termsAndConditions, isMultipleVerified } = store.state.globalComponents
          if (multipleAccount.length > 1 && termsAndConditions && !isMultipleVerified) {
            router.push({ name: 'choose_account' })
            return
          }

          store.commit('auth/setAuthUser', true)
          useSockets()
          if (route?.params?.p) {
            router.push({ path: route.path, params: { p: route.params.p } })
          } else {
            router.push({ path: route.path })
          }
        } else if (access_token !== null && otp_verified === false && otpType !== null) {
          store.commit('auth/setAuthUser', false)
          const otpType = tokenStorage.getByKey('otp-type')
          store.commit('auth/setOtpType', otpType)
          router.push({ name: 'otp' })
        } else {
          store.commit('auth/setAuthUser', false)
          tokenStorage.setByKey('otp_verified', false)
          router.push({ path: '/login' })
        }
      }
    })

    return {
      loading,
      isAuthenticated,
      isUserLoggedIn
    }
  },

  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      window.onload = fetch(fetchTransport.baseUrl + '/ping').then((response) => {
        if (response && response.status === StatusCodes.SERVICE_UNAVAILABLE) {
          this.$router.push('/503')
        }
      })
    },
  },
}
</script>

<style lang="css">
@import '~element-plus/dist/index.css';
@import '~element-plus/theme-chalk/display.css';
@import '~cropperjs/dist/cropper.css';
</style>
