<template>
  <el-dialog
    v-model="dialogVisible"
    title="Preview"
    width="90%"
    :before-close="closeDialog"
    destroy-on-close
    class="mt-10 sm:mt-40 lg:mt-16"
  >
    <div v-if="previewFile" class="overflow-y-scroll max-h-[65vh]">
      <template v-if="previewFile.extension">
        <img
          v-if="configImageExtension.includes(previewFile.extension.toLowerCase())"
          :src="previewFile.url"
          alt="preview-image"
        />
        <SwdPdfViewer
          v-if="previewFile.extension.toLowerCase() === 'pdf'"
          type="application/pdf"
          :src="previewFile.url"
          :page="1"
        />

        <!-- CSV Viewer (Child Component) -->
        <div v-if="csvData.length && previewFile.extension.toLowerCase() === 'csv'">
          <CsvViewer :csv-data="csvData" :column-defs="columnDefs"  />
        </div>

        <iframe
          v-if="configMicrosoftExtension.includes(previewFile.extension.toLowerCase())"
          :src="getSrcMicrosoft"
          width="100%"
          height="500px"
          frameborder="0"
        />
      </template>

      <template v-else>
        <iframe
          v-if="configMicrosoftExtension.includes(previewFile?.media?.extension.toLowerCase())"
          :src="getSrcMicrosoft"
          width="100%"
          height="500px"
          frameborder="0"
        />
      </template>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <div class="flex justify-end">
          <SwdButton class="px-4 py-1" info @click="closeDialog">Close</SwdButton>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { useStore } from 'vuex'
import { ref, watchEffect, computed } from 'vue'
import SwdPdfViewer from '@/components/Global/SwdPdfViewer'
import Papa from 'papaparse'
import CsvViewer from '@/components/Global/CsvViewer' // Import the child component

export default {
  name: 'SwdPreviewModal',
  components: {
    SwdPdfViewer,
    CsvViewer, // Register the child component
  },
  setup() {
    const store = useStore()
    const dialogVisible = ref(false)
    const configImageExtension = ['jpeg', 'jpg', 'png']
    const configMicrosoftExtension = ['doc', 'docx', 'xls', 'xlsx']
    const previewFile = computed(() => store.state.globalComponents.previewFile)

    watchEffect(() => {
      dialogVisible.value = store.state.globalComponents.dialog.showDialog.previewModal
    })

    const csvData = ref([]) // Stores parsed CSV data
    const columnDefs = ref([]) // Column definitions for the table
    const pageSize = ref(10) // Page size for pagination

    const parseCsvFromBlob = (blob) => {
      if (blob instanceof Blob) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const csvContent = event.target.result
          console.log('CSV Content Loaded:', csvContent)

          Papa.parse(csvContent, {
            complete: (result) => {
              console.log('CSV Parsing Result:', result)
              handleParseComplete(result)
            },
            header: true, // Use first row as header
            skipEmptyLines: true,
            dynamicTyping: true,
            error: (error) => {
              console.error('CSV Parsing Error:', error)
            },
          })
        }
        reader.readAsText(blob)
      }
    }

    const handleParseComplete = (result) => {
      if (result.data && result.data.length > 0) {
        columnDefs.value = result.meta.fields.map((field) => ({
          field: field,
        }))

        csvData.value = result.data.map((row) => {
          return Object.keys(row).reduce((acc, key) => {
            acc[key] = row[key] || ''
            return acc
          }, {})
        })
      } else {
        columnDefs.value = result.meta.fields.map((field) => ({
          field: field,
        }))

        csvData.value = Array(20).fill(
          result.meta.fields.reduce((acc, field) => {
            const normalizedKey = field
            acc[normalizedKey] = ''
            return acc
          }, {})
        )
      }

      console.log('Column Definitions:', columnDefs.value)
      console.log('CSV Data Loaded:', csvData.value)
    }

    // Watch for file changes and parse CSV if applicable
    watchEffect(() => {
      if (previewFile.value && previewFile.value.extension) {
        const ext = previewFile.value.extension.toLowerCase()
        if (ext === 'csv') {
          const file = previewFile.value.url
          if (file instanceof Blob || file instanceof File) {
            parseCsvFromBlob(file)
          }
        }
      }
    })

    const closeDialog = () => {
      dialogVisible.value = false
      store.commit('globalComponents/setShowModal', {
        destination: 'previewModal',
        value: false,
      })
      store.commit('globalComponents/setPreviewFile', null)
    }

    const getSrcMicrosoft = computed(() => {
      let fileURL = previewFile?.value?.url || previewFile?.value?.media?.url
      return 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(fileURL)
    })

    return {
      closeDialog,
      previewFile,
      csvData,
      columnDefs,
      pageSize,
      dialogVisible,
      configImageExtension,
      configMicrosoftExtension,
      getSrcMicrosoft,
    }
  },
}
</script>
