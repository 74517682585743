<template>
  <div class="flex w-full">
    <SwdSideClientAdvisorBar />
    <div class="flex flex-col w-[95%]">
      <div class="relative bg-main-gray items-center h-16 flex px-3">
        <ClientPreviewHeader />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import SwdSideClientAdvisorBar from '@/components/Global/SwdSideClientAdvisorBar.vue'
import ClientPreviewHeader from '@/components/Header/RolesForHeader/ClientPreviewHeader.vue'

export default {
  name: 'ClientAdvisorHome',

  components: {
    SwdSideClientAdvisorBar,
    ClientPreviewHeader,
  },
}
</script>
