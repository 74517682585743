<template>
  <div class="csv-viewer-container">
    <el-table
      v-if="paginatedCsvData.length > 0"
      :data="paginatedCsvData"
      stripe
      class="w-full table-fixed"
    >
      <el-table-column
        v-for="(column, index) in columnDefs"
        :key="index"
        :label="column.field"
        :prop="column.field"
        :width="160"
        show-overflow-tooltip
      >
        <template #header>
          <span class="block transform origin-bottom-left text-sm font-semibold text-gray-700">
            {{ column.field }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="mt-4 flex justify-center">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :page-count="pageCount"
        layout="prev, pager, next"
        class="pagination p-2"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "CsvViewer",
  props: {
    csvData: {
      type: Array,
      required: true,
    },
    columnDefs: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const currentPage = ref(1);
    const pageSize = ref(10);

    // Calculate paginated data
    const paginatedCsvData = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      return props.csvData.slice(start, start + pageSize.value);
    });

    // Calculate total page count
    const pageCount = computed(() => Math.ceil(props.csvData.length / pageSize.value));

    // Handle page size change
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      currentPage.value = 1; // Reset to first page
    };

    // Handle page change
    const handlePageChange = (newPage) => {
      currentPage.value = newPage;
    };

    return {
      currentPage,
      pageSize,
      paginatedCsvData,
      pageCount,
      handleSizeChange,
      handlePageChange,
    };
  },
};
</script>

<style scoped>
/* Style for the outer container to allow scrolling for the entire component */
.csv-viewer-container {
  max-height: calc(100vh - 200px); /* Adjust this value to control the dialog height */
  overflow-y: none; /* Enable scrolling for the whole component */
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

/* Pagination style adjustments */
.pagination .el-select {
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
 .el-overlay-dialog{
  overflow: hidden!important; /* Enable scrolling for the whole component */

}
</style>
