<template>
  <el-button type="primary" class="mr-5" :icon="Back" size="medium" @click="goToAdvisor"> Back to Advisor </el-button>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { Back } from '@element-plus/icons-vue'

export default {
  name: 'BackToAdvisor',
  setup() {
    const router = useRouter()
    const route = useRoute()

    const goToAdvisor = () => {
      try {
        const memberId = route.path.match(/\/([a-zA-Z0-9]{15,})\b/)?.[1]

        if (!memberId) {
          console.error('Member ID not found in the URL')
          return
        }

        // Navigate to the advisor URL
        router.push(`/advisor/member/${memberId}/member-details`)
      } catch (error) {
        console.error('Error during navigation:', error)
      }
    }

    return {
      Back,
      goToAdvisor,
    }
  },
}
</script>
