import { computed } from 'vue'
import { useFetchInit } from '@/api/use-fetch-init'
import { useSetUpdateAbility } from '@/hooks/use-set-update-ability'
import { useRoutRedirect } from '@/hooks/use-rout-redirect'
import store from '@/store'
import ROLE from '../enums/roles'
import router from '../router/index'
import { useAlert } from '@/utils/use-alert'

export function useSetInit() {
  const { response, error, getInit } = useFetchInit()
  const { setUpdateAbility } = useSetUpdateAbility()
  const { routRedirect } = useRoutRedirect()

  const getUserId = computed(() => {
    // Use optional chaining and nullish coalescing for safer property access
    return response.value?.data?.roles[0] === ROLE.CLIENT ? response.value.data.member_id : response.value.data.user_id
  })

  const setInit = async (to = {}) => {
    store.commit('globalComponents/setIsLoadingApp', true)
    await getInit()

    if (!error.value) {
      const data = response.value.data
      let role = data.roles[0]
      const termsAndConditions = data.terms_and_conditions
      const readOnly = data.readonly
      const clients = data.clients
      const termsAndConditionsInfo = data.terms_and_conditions_info
      const trcmURL = data.trcm_url

      // Use destructuring to simplify assignment
      store.commit('globalComponents/setRole', role)
      store.commit('globalComponents/setTrcmData', data.trcm)
      store.commit('globalComponents/setCurrentTypeUser', role)
      store.commit('globalComponents/setCurrentCompanyId', data.company_id)
      store.commit('globalComponents/setUserId', data.user_id)
      store.commit('globalComponents/setMultipleAccount', clients)
      store.commit('globalComponents/setTermsAndConditions', termsAndConditions)
      store.commit('globalComponents/setTermsAndConditionsInfo', termsAndConditionsInfo)
      store.commit('globalComponents/setTrcmURL', trcmURL)
      store.commit('globalComponents/setMultipleAccountVerified', true)
      store.commit('globalComponents/setNoOfClientAccount', clients?.length || 1)
      store.commit('globalComponents/setTRCMSecrets', data?.trcm_secrets) 


      const roleCommits = {
        [ROLE.ADMIN]: 'setAdminId',
        [ROLE.CEO]: 'setCeoId',
        [ROLE.ADVISOR]: 'setAdvisorId',
        [ROLE.CLIENT]: 'setClientId',
        [ROLE.LEAD]: 'setLeadId',
        [ROLE.ASSISTANT]: 'setAdvisorId',
        [ROLE.SUPPORT]: 'setSupportId',
      }

      // Check if the role exists in roleCommits before committing
      if (roleCommits[role]) {
        store.commit('globalComponents/' + roleCommits[role], getUserId.value)
      }

      const { noOfClientAccount } = store.state.globalComponents

      if (role === ROLE.CLIENT || role === ROLE.LEAD) {
        // Check if `clients` is defined and not empty
        if (!clients || clients.length === 0) {
          sessionStorage.clear()
          router.push({ name: 'logout' })
          useAlert({
            title: 'Error',
            type: 'error',
            message: 'No user found.',
          })
          console.log('No clients found.')
          return
        }

        const defaultClient = clients[0]

        // Ensure `defaultClient` is defined before accessing its properties
        if (!defaultClient || defaultClient.custom_role === undefined) {
          console.log('Default client or custom role is undefined.')
          router.push({ name: 'logout' })
          return
        }

        if (!termsAndConditions) {
          store.commit('globalComponents/setClientId', defaultClient.member_id)
          router.push({ name: 'terms' })
          return
        } else if (noOfClientAccount > 1) {
          store.commit('globalComponents/setMultipleAccountVerified', false)
          router.push({ name: 'choose_account' })
          return
        }

        // Simplify logic to set getUserId.value based on defaultClient.custom_role
        getUserId.value = (defaultClient.custom_role === ROLE.LEAD || defaultClient.custom_role === ROLE.CLIENT) ? defaultClient.member_id : data.user_id
        const clientId = (defaultClient.custom_role === ROLE.LEAD || defaultClient.custom_role === ROLE.CLIENT) ? defaultClient.id : getUserId.value
        role = defaultClient.custom_role

        store.commit('globalComponents/setClientId', clientId)
        store.commit('globalComponents/setRole', defaultClient.custom_role)
        store.commit('globalComponents/setMemberId', defaultClient.member_id)
        store.commit('globalComponents/setCurrentTypeUser', defaultClient.custom_role)
        store.commit('globalComponents/' + roleCommits[defaultClient.custom_role], getUserId.value)
      }

      if (role === ROLE.LEAD && readOnly) {
        store.commit('globalComponents/setShowModalReadOnly', readOnly)
      }

      await setUpdateAbility()

      // Simplify condition for routRedirect
      if (to.name !== 'telegram-login') {
        routRedirect({ role, userId: getUserId.value })
      }

      setTimeout(() => {
        store.commit('globalComponents/setIsLoadingApp', false)
      }, 1000)
    }
  }

  return { setInit }
}
