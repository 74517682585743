<template>
  <div class="font-semibold text-sm text-gray-700 leading-tight tracking-normal">
    <el-breadcrumb :separator-icon="ArrowRight">
      <el-breadcrumb-item
        v-if="breadCrumbsData.length"
        :key="'home'"
        :to="breadCrumbsData[0].to"
        class="flex items-center text-gray-600 pt-2 hover:text-gray-800"
      >
        <el-icon class="text-xl mr-1"><HomeFilled /></el-icon>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(item, index) in breadCrumbsData.slice(1)"
        :key="index + 1"
        :to="item.to"
        class="mt-3 text-gray-600 hover:text-gray-800"
      >
        {{ item.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { ArrowRight } from '@element-plus/icons-vue'
import { HomeFilled } from '@element-plus/icons-vue'

export default {
  name: 'Breadcrumb',
  components: {
    HomeFilled, // Register HomeFilled icon here
  },
  props: {
    breadCrumbsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ArrowRight,
    }
  },
}
</script>

<style scoped>
/* No additional styles needed since Tailwind is used */
</style>
