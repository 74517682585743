<template>
  <div class="flex justify-end items-center">
    <SwdSpinner v-if="isLoadingUserProfile" />
    <template v-else>
      <router-link v-if="!isLead" :to="{ name: 'profile' }">
        <SwdAvatar v-if="user && !isLoadingUserProfile" :link="user?.avatar?.url" />
      </router-link>
      <SwdAvatar v-if="user && !isLoadingUserProfile && isLead" :link="user?.avatar?.url" />
      <SwdDropDown :options="getActionsOptions" @select="handleSelect">
        <template #titleDropDown>
          <div v-if="!isLoadingUserProfile" class="flex items-center cursor-pointer">
            <span class="px-2 py-2 text-xs uppercase justify-center font-semibold text-primary">{{
              getNameTitle
            }}</span>
            <InlineSvg :src="IconUserAction" />
          </div>
        </template>
      </SwdDropDown>
    </template>
  </div>
</template>
<script>
import IconUserAction from '@/assets/svg/icon-user-action.svg'
import { useRouter } from 'vue-router'
import { useUserProfile } from '@/api/use-user-profile.js'
import { computed } from 'vue'
import { useStore } from 'vuex'
import ROLE from '../enums/roles'

export default {
  name: 'UserAction',
  props: {
    idAdminPanel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()
    //const advisorDetails = store.state.globalComponents.advisorDetails

    const actionsOptionsMagicUser = [{ title: 'Logout', command: 'logout', class: 'font-medium' }]

    const actionsOptionsAdmin = [
      { title: 'Profile', command: 'profile' },
      { title: 'Settings', command: 'settings' },
      { title: 'Admin panel', command: 'admin-panel' },
      { title: 'Logout', command: 'logout', class: 'font-medium' },
    ]

    const actionsOptionsAdminPanel = [
      { title: 'SWD platform', command: 'sdw-platform' },
      { title: 'Logout', command: 'logout', class: 'font-medium' },
    ]

    const getActionsOptions = computed(() => {
      if (
        (store.state.globalComponents.role === ROLE.ADMIN || store.state.globalComponents.role === ROLE.CEO) &&
        !props.idAdminPanel
      ) {
        return actionsOptionsAdmin
      }

      if (
        (store.state.globalComponents.role === ROLE.ADMIN || store.state.globalComponents.role === ROLE.CEO) &&
        props.idAdminPanel
      ) {
        return actionsOptionsAdminPanel
      }

      if (store.state.globalComponents.currentTypeUser === ROLE.LEAD) {
        return actionsOptionsMagicUser
      }

      // Conditionally remove 'Profile' and 'Settings' if advisorDetails exists
      let options = [
        { title: 'Profile', command: 'profile' },
        { title: 'Settings', command: 'settings' },
        { title: 'Logout', command: 'logout', class: 'font-medium' },
      ]

      // if (advisorDetails) {
      //   options = options.filter((option) => option.title !== 'Profile' && option.title !== 'Settings')
      // }

      return options
    })

    const getNameTitle = computed(() => {
      if (!user.value?.firstName && !user.value?.lastName) return 'User'
      return user.value.firstName.charAt(0) + user.value.lastName.charAt(0)
    })

    const isLead = computed(() => {
      return store.state.globalComponents.currentTypeUser == ROLE.LEAD
    })

    const actionsMap = {
      logout: () => router.push({ name: 'logout' }),
      profile: () => router.push({ name: 'profile' }),
      'admin-panel': () => {
        showLoading()
        const adminRole = store.state.globalComponents.role
        router.push({ name: `${adminRole}/ap-dashboard` })
      },
      'sdw-platform': () => {
        showLoading()
        router.push({ name: 'home' })
      },
      settings: () => router.push({ name: 'settings' }),
    }

    const { isLoading: isLoadingUserProfile, isError: isErrorUserProfile, data: user } = useUserProfile()

    const handleSelect = (command) => {
      const actionHandler = actionsMap[command]
      actionHandler()
    }

    const showLoading = () => {
      store.commit('globalComponents/setIsLoadingApp', true)
      setTimeout(function () {
        store.commit('globalComponents/setIsLoadingApp', false)
      }, 1000)
    }

    return {
      handleSelect,
      IconUserAction,
      isLoadingUserProfile,
      isErrorUserProfile,
      user,
      isLead,
      getNameTitle,
      getActionsOptions,
    }
  },
}
</script>
