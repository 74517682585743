import { useRouter, useRoute } from 'vue-router'
import ROLE from '../enums/roles'

export function useGetDetails() {
  const router = useRouter()
  const route = useRoute()

  const getDetails = ({ member }) => {
    if (member.type === ROLE.CLIENT || member.step !== 'default') {
      router.push({ name: `${route.meta.type}/member-details`, params: { id: member.id } })
      return
    }

    if (member.step === 'default') {
      router.push({ name: 'basic-information', params: { id: member.id } })
      return
    }
  }
  return { getDetails }
}
