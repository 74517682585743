<template>
  <SwdButton small primary class="mr-2" @click="openTRCMVerification">
    <img
      class="el-upload-list__item-thumbnail"
      width="70"
      height="70"
      src="../../assets/img/visn.png"
      alt="maintenance page"
    />
  </SwdButton>
</template>

<script>
// import store from '@/store'
import { useShowContentEnv } from '@/hooks/use-show-content-env'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'NewTRCMbtn',
  setup() {
    const { showContent } = useShowContentEnv()
    const router = useRouter()
    const route = useRoute()
    const openTRCMVerification = () => {

      if (router) {
        router.push({ name: `${route.meta.type}/trcm` })
      } else {
        console.error('Router instance is undefined')
      }
    }
    return {
      openTRCMVerification,
      showContent,
    }
  },
}
</script>
