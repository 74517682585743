<template>
  <aside class="bg-primary min-h-screen flex-col w-[5%] sm:flex hidden content minWidth">
    <div class="flex flex-col items-center flex-grow w-[65px] fixed top-[15%]">
      <router-link
        v-if="$can('client', 'all')"
        :to="{ name: `client-preview/member-details`, params: { id: memberId } }"
      >
        <InlineSvg :src="IconDashboardActive" />
      </router-link>
    </div>
  </aside>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import IconList from '@/assets/svg/icon-list.svg'
import IconListActive from '@/assets/svg/list-sidebar-active.svg'
import IconAssets from '@/assets/svg/icon-assets.svg'
import IconAssetsActive from '@/assets/svg/icon-assets-active.svg'
import IconDashboard from '@/assets/svg/dashboard-sidebar.svg'
import IconDashboardActive from '@/assets/svg/dashboard-sidebar-active.svg'
import IconActivityActiv from '@/assets/svg/icon-activity-activ.svg'
import IconActivityGray from '@/assets/svg/icon-activity-gray.svg'
import IconActivityPipeLine from '@/assets/svg/icon-pipeline-activ.svg'
import IconPipeLine from '@/assets/svg/icon-pipeline.svg'
import IconUserActive from '@/assets/svg/leads-active.svg'
import IconUserFade from '@/assets/svg/leads-fade.svg'
import IconUser from '@/assets/svg/icon-empty-users.svg'
import IconLeads from '@/assets/svg/icon-leads.svg'
import IconLeadsActive from '@/assets/svg/icon-leads-active.svg'
import IconLogs from '@/assets/svg/icon-logs.svg'
import IconLogsActive from '@/assets/svg/icon-logs-active.svg'
import IrisIconReverse from '@/assets/svg/iris-icon-reverse.svg'
import IconProspectActive from '@/assets/svg/prospect-active.svg'
import IconProspect from '@/assets/svg/prospect-fade.svg'
import ROLE from '../../enums/roles'

export default {
  name: 'SwdSideBar',
  components: {},

  setup() {
    const store = useStore()

    const route = useRoute()

    const memberId = store.state.globalComponents.clientId
    const isClientRole = computed(() => getType.value === ROLE.CLIENT)

    const getRouteName = computed(() => {
      return route.name
    })
    const getActiveListOfHouseholds = computed(() => {
      return (
        getRouteName.value === `${getType.value}/all` ||
        getRouteName.value === `${getType.value}/clients` ||
        getRouteName.value === `${getType.value}/opportunities` ||
        getRouteName.value === `${getType.value}/list-of-advisors`
      )
    })

    const getActivedefault = computed(() => {
      return (
        getRouteName.value === `${getType.value}/account` ||
        getRouteName.value === `${getType.value}/transactions` ||
        getRouteName.value === `${getType.value}/position` ||
        getRouteName.value === `${getType.value}/tcf` ||
        getRouteName.value === `${getType.value}/security` ||
        getRouteName.value === `${getType.value}/profile`
      )
    })

    const isShowSideBar = computed(() => {
      if (!store.state.auth.isAuth) return false

      const hiddenRoles = [ROLE.CLIENT, ROLE.LEAD, ROLE.ADMIN, ROLE.CEO]

      return !hiddenRoles.includes(getType.value)
    })

    const getType = computed(() => {
      if (route.meta.type) return route.meta.type
      return store.state.globalComponents.role
    })

    const isSchwabRouteAllowed = computed(() =>
      [
        `${getType.value}/account`,
        `${getType.value}/transactions`,
        `${getType.value}/position`,
        `${getType.value}/security`,
        `${getType.value}/tcf`,
        `${getType.value}/profile`,
      ].includes(getRouteName.value)
    )

    const isAnnuityRoute = computed(() => getRouteName.value === 'annuity')

    const isLeadRoute = computed(() => {
      return (
        getRouteName.value === `${getType.value}/all-leads` ||
        getRouteName.value === `${getType.value}/active-leads` ||
        getRouteName.value === `${getType.value}/deactivated-leads`
      )
    })

    const leadIcon = computed(() => (isLeadRoute.value ? IconUserActive : IconUserFade))

    return {
      IconList,
      IconAssets,
      IconDashboard,
      IconDashboardActive,
      IconListActive,
      getRouteName,
      getActiveListOfHouseholds,
      memberId,
      getActivedefault,
      isClientRole,
      IconActivityGray,
      IconActivityActiv,
      IconActivityPipeLine,
      IconPipeLine,
      IconUserActive,
      IconUserFade,
      IconUser,
      IconAssetsActive,
      IconLeads,
      IconLeadsActive,
      IconLogs,
      IconLogsActive,
      IrisIconReverse,
      route,
      isShowSideBar,
      getType,
      isSchwabRouteAllowed,
      isAnnuityRoute,
      isLeadRoute,
      leadIcon,
      IconProspectActive,
      IconProspect,
    }
  },
}
</script>

<style lang="css" scoped>
.centerText {
  text-align: center;
}

.active {
  background-color: rgba(103, 116, 148, 0.1);
}

.item:hover {
  background-color: rgba(103, 116, 148, 0.1);
}

.minWidth {
  min-width: 67px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .content {
    max-width: 800px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .content {
    max-width: 800px;
    margin: 0 auto;
  }
}
</style>
