<template>
  <div>
    <div class="flex pb-2 items-center">
      <div>
        <BreadCrumb :breadCrumbsData="breadCrumbsData" />
      </div>
    </div>
    <div v-if="title" class="flex flex-col w-11/12 py-3">
      <div class="text-base sm:text-title flex text-primary font-semibold text-center
              font-inter text-[28px] leading-none tracking-normal">
        {{ title }}
      </div>

      <div v-if="info" class="flex item-center pt-3">
        <p class="text-center text-sm font-inter text-[16px] leading-none tracking-normal">
          {{ info }}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import BreadCrumb from './Header/BreadCrumb.vue'

export default {
  name: 'SwdSubHeader',
  components: {
    BreadCrumb,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    info: {
      type: String,
      required: false,
      default: '',
    },
    breadCrumbsData: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  setup(props) {
    const router = useRouter()

    const back = () => {
      router.push({ name: props.backPage })
    }

    return {
      back,
    }
  },
}
</script>
