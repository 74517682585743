import { useFetch } from '@/api/use-fetch'
import { useAlert } from '@/utils/use-alert'

const useWelcomeEmail = (member_id) => {
  const { response, error, fetching, fetchData } = useFetch(`/client/welcome-email/${member_id}`, { method: 'GET' })

  const sendWelcomeEmail = async (body) => {
    try {
      await fetchData({ body })
      if (!error.value) {
        useAlert({
          type: 'success',
          title: 'Alert',
          message: 'Welcome email sent successfully.',
        })
      } else {
        useAlert({
          type: 'error',
          title: 'Error',
          message: error.value,
        })
      }
    } catch (err) {
      useAlert({
        type: 'error',
        title: 'Error',
        message: 'An unexpected error occurred.',
      })
      console.error(err)
    }
  }

  return {
    response,
    error,
    fetching,
    sendWelcomeEmail,
  }
}
export { useWelcomeEmail }